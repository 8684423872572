import { BoundedContent, Column, Divider, Link, ResponsiveImage, Row } from '@vp/swan'

type CheckoutHeaderProps = {
  storeLogo: string;
  locale: string;
}

export const CheckoutHeader = ({ storeLogo, locale }: CheckoutHeaderProps) => {
  const navigateToHomePage = async () => {
    window.location.href = `${window.location.origin}/${locale}`
  }

  return (
    <>
      <BoundedContent style={{ maxWidth: '1440px' }}>
        <Row paddingTop={4}>
          <Column span={2}>
            <Link href='' onClick={navigateToHomePage}>
              <ResponsiveImage src={storeLogo} height={64} loading='eager' />
            </Link>
          </Column>
        </Row>
        <Divider />
        <Row paddingBottom={4} />
      </BoundedContent>
    </>
  )
}
