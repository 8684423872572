import { clientMount } from '@vp/ubik-fragment-react'
import type { MountWithRootOptions } from '@vp/ubik-fragment-types'
import { Fragment, } from '../components/fragment'
import { IdentityProvider } from '@vp/ubik-context'
import { createAuthConfig } from '../utils/auth'

type Props = {
  // Define the properties of Props here
}

export const mount: MountWithRootOptions<Props> = async (rootElement, renderProps, rootOptions) => {
  // const { locale } = renderProps

  const component = (
    <IdentityProvider auth={createAuthConfig('en-US')}>
      <Fragment greeting='' {...renderProps} />
    </IdentityProvider>
  )
  return clientMount(component, rootElement, rootOptions)
}
