import { type IdentityProviderProps } from '@vp/ubik-context'

type VPAuthConfig = IdentityProviderProps['auth']

export const createAuthConfig = (culture: string): VPAuthConfig => ({
  culture,
  options: {
    clientID: 'nMpxos502IwOcaAuKEfKKNcOOV4wXTXR',
    site: 'vcs',
    storeId: '3dbf6f0e-a539-46f0-9f68-a49011d620b2',
    vcsStoreUrl: 'http://localhost:3000',
    vcsStoreLogoUrl:
      'https://cms.cloudinary.vpsvc.com/image/upload/v1742285885/VCS/VCS_DEV/config-service/Test-Logo.svg_2025_03_18T08_18_pmoCT.png.png',
    requireSession: true,
  },
})
