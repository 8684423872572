import { useSwanStyleKeys, useUserContext } from '@vp/ubik-context'
import { CheckoutHeader } from './CheckoutHeader'

export interface Props {
  greeting: string
}

export const Fragment = (props: Props) => {
  const userContext = useUserContext()
  const locale = userContext.locale || 'en-US'

  useSwanStyleKeys(['core'])

  return (
    <>
      <CheckoutHeader
        storeLogo='https://cms.cloudinary.vpsvc.com/image/upload/v1683280352/VCS/global_images/Logo.png'
        locale={locale}
      />
    </>
  )
}
